body:not(.cms)  {

	.markdown-content {

		*  {

			color: unset;
		}

		img {
			
			max-width: 100%;
			height: auto;
			margin: 50px auto;
			display: block;
		}

		strong {

			font-weight: 800;
		}

		em {

			font-style: italic;
		}

		sub {

			vertical-align: sub;
			font-size: smaller;
		}

		sup {

			vertical-align: super;
			font-size: smaller;
		}

		h1,
		h2 {

			color: var(--medium-red-color);
			font-size: 40px;
			line-height: 50px;
			font-weight: 500;
			margin-top: 70px;	
			margin-bottom: 60px;
		}

		h3 {
			
			font-size: 30px;
			font-style: normal;
			font-weight: 500;
			line-height: 40px;
			letter-spacing: 0em;
			margin-top: 50px;
			margin-bottom: 30px;
		}

		h4,
		p {

			font-size: 18px;
			font-style: normal;
			line-height: 32px;
			letter-spacing: 0.02em;
			margin-top: 15px;
			margin-bottom: 15px;
		}

		h4 {

			font-weight: 800;
		}

		p {

			font-weight: 300;
		}

		a {
			
			transition: color $timeToColor;
			color: var(--medium-red-color);
			text-decoration: none;
			font-weight: 500;
			font-size: 17px;
			line-height: 30px;
			letter-spacing: 0.02em;

			&:active {

				color: var(--dark-red-color);
			}
		}

		& > a {

			position: relative;
			display: block;
			margin-top: 50px;
			margin-bottom: 50px;
			font-size: 18px;
			font-weight: 500;
			padding: 14px 35px;
			text-align: center;
			border: 1.5px solid var(--medium-red-color);
			background-color: var(--medium-red-color);
			color: var(--text-light);
			border-radius: 30px;
			width: max-content;
			transition: color $timeToColor, background-color $timeToColor;

			&:active {

				color: var(--medium-red-color);
				background-color: var(--pink-color);
			}
		}

		ol,
		ul {

			padding-left: 35px;
			margin-top: 30px;
			margin-bottom: 30px;

			li {

				position: relative;
				font-size: 17px;
				font-style: normal;
				font-weight: 300;
				line-height: 30px;
				letter-spacing: 0.02em;
				margin-top: 10px;
				margin-bottom: 10px;
			}
		}

		ul {

			li {

				&:before {

					position: absolute;
					content: '';
					width: 10px;
					height: 10px;
					background-color: var(--medium-red-color);
					border-radius: 50%;
					top: 9px;
					left: -35px;
				}
			}
		}

		ol {

			li {

				&:before {

					position: absolute;
					left: -35px;
				}
			
				@for $i from 0 through 20 {

					&:nth-child(#{$i}) {

						&:before {

							content: $i + '.';
							font-size: 17px;
							font-style: normal;
							font-weight: 800;
							line-height: 30px;
							letter-spacing: 0.02em;
							color: var(--medium-red-color);
						}
					}
				}
			}
		}

		*:first-child {

			margin-top: 0px;
		}

		*:last-child {

			margin-bottom: 0px;
		}

		@media(min-width: $limit_mobile) {

			a {

				&:hover {

					color: var(--dark-red-color);
				}
			}

			& > a {
				
				&:hover {

					color: var(--medium-red-color);
					background-color: var(--pink-color);
				}
			}

		}

		@media(max-width: $limit_mobile) {

			h1, h2 {

				font-size: 20px;
				line-height: 30px;
				letter-spacing: 0.02em;
				margin-bottom: 40px;
			}

			h3 {
			
				font-size: 18px;
				font-weight: 500;
				line-height: 26px;
				margin-top: 40px;
			}

			h4,
			p {

				font-size: 16px;
				line-height: 23px;
				letter-spacing: 0.02em;
			}

			a {
			
				font-weight: 500;
				font-size: 16px;
				line-height: 23px;
			}

			& > a {

				margin-top: 30px;
				margin-bottom: 30px;
				font-size: 14px;
				font-weight: 500;
				letter-spacing: 0;
				line-height: 25px;
				padding: 10px 35px;

				&:before {
					
					bottom: 1px;
				}

				&:after {

					right: -14px;
					top: calc(50% + 2px);
					width: 6px;
					height: 12px;
				}
			}

			ol,
			ul {

				padding-left: 20px;

				li {

					font-size: 16px;
					line-height: 23px;
				}
			}

			ul {

				li {

					&:before {

						width: 6px;
						height: 6px;
						top: 9px;
						left: -20px;
					}
				}
			}

			ol {

				li {

					&:before {

						left: -20px;
						top: -3px;
					}
				
					@for $i from 0 through 20 {

						&:nth-child(#{$i}) {

							&:before {

								font-size: 16px;
							}
						}
					}
				}
			}
		}
	}
}