body {
    display: block;
    margin: 0px;
}

a, button{
    cursor: pointer;
}

.enchant-s1 {
    display: flex;
    padding: 3.8vh 7.7vh 3.8vh 7.7vh;
    border-bottom: 1px solid #D3D8DC;
    justify-content: space-between;
    align-items: center;
}

.enchant-s1 button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 32vh;
    height: 6.3vh;
    border-radius: 1.3vh;
    border-width: 1px;
    padding: 0px 2.5vh 0px 2.5vh;
    border: 1px solid #ED0E11;  
    background-color: white;
    color: #ED0E11;
    font-family: Montserrat;
    font-weight: 600;
    font-size: 2.2vh;
    line-height: 100%;
    letter-spacing: 0px;
    text-align: center;
    &:hover{
        border: 1px solid white;  
        background-color: #ED0E11;
        color: white;
        box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
        transform: scale(1.05);
    }
}

@media (max-width: 380px) {
    .enchant-s1 button {
        width: 27vh;
        font-size: 1.7vh;
    }
}

.enchant-s1 button span {
    width: 3.8vh;
    height: 3.8vh;
    background-repeat: no-repeat;
    background-color: unset;
    background-position: center;
    display: block;
    cursor: pointer;
}

.enchant-s1 .enchant-s1-logo {
    width: 31.3vh;
    height: 5.5vh;
    background-repeat: no-repeat;
    background-color: unset;
    background-position: center;
    background-size: contain;
    display: block;
    cursor: pointer;
}

.enchant-s2 {
    display: flex;
    height: 13%;
    top: 57px;
    left: 144px;
    gap: 99px;  
    justify-content: space-between;
}

.enchant-s2-texto {
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    gap: 3.8vh;
}

.enchant-s2-left {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 9%;
}

.enchant-s2 img{
    width: 44%;
    height: auto;
    object-fit: fill;
    border-bottom-left-radius: 30vh;  
}

.enchant-s2 h1{
    font-family: Montserrat;
    font-weight: 600;
    font-size: 6.3vh;
    line-height: 120%;
    letter-spacing: 0px;
    color: #242424;
}

.enchant-s2 p {
    font-family: Montserrat;
    font-weight: 400;
    font-size: 3vh;
    line-height: 150%;
    letter-spacing: 0px;
    color: #242424;
}

.enchant-s2 button {
    width: 43%;
    height: 8vh;
    gap: 8px;
    border-radius: 1.3vh;
    border: none;
    background: #ED0E11;
    color:white;
    font-family: Montserrat;
    font-weight: 700;
    font-size: 2.5vh;
    line-height: 3.9vh;
    letter-spacing: 0px;
    text-align: center;

    &:hover{
        border: 1px solid #ED0E11;  
        background-color: white;
        color: #ED0E11;
        box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
        transform: scale(1.05);
    }
}

.enchant-s3 .card {
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 362px;
}

.enchant-s3 .slick-slide {
    margin: 0 10px;
}

.enchant-s3 .card img{
    width: 100%;
    height: 46%;
    border-radius: 1.3vh;
    margin-bottom: 3.8vh;
}

.enchant-s3-title{
    display: flex;
    text-align: center;
    flex-direction: column;
    align-items: center;
    margin-bottom: 7vh;
    gap:16px;
}

.enchant-s3-title label{
    font-family: Montserrat;
    font-weight: 600;
    font-size: 6.3vh;
    line-height: 120%;
    letter-spacing: 0px;
    text-align: center;
    color: #242424;

}

.enchant-s3-title p {
    font-family: Montserrat;
    font-weight: 400;
    font-size: 2.9vh;
    line-height: 150%;
    letter-spacing: 0px;
    text-align: center;
    color: #242424;
    width: 54%;
}

.container-card-text {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.enchant-s3-carrossel {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    gap: 16px;
}

.enchant-s3-carrossel label{
    font-family: Montserrat;    
    font-weight: 600;
    font-size: 3.7vh;
    line-height: 130%;
    letter-spacing: 0px;
    text-align: center;
    color: #242424;
}

.enchant-s3-carrossel p{
    font-family: Montserrat;
    font-weight: 400;
    font-size: 2.5vh;
    line-height: 170%;
    letter-spacing: 0px;
    text-align: center;    
    color: #242424;
}

.enchant-s3-slides {
    width: 88%;
    display: flex;
    gap: 16px;
}

.enchant-s3-carrossel-prev {
    position: absolute;
    top: 50%;
    left: -47px;
    transform: translateY(-50%) scaleX(-1); 
    width: 48px;
    height: 48px;
    background-image: url("images/icon-arrow-right-car-active-lp-enchant.svg");
    background-repeat: no-repeat;
    background-color: unset;
    background-position: center;
    display: block;
    cursor: pointer;
    border: none;
}

.enchant-s3-carrossel-next{
    position: absolute;
    top: 50%;
    right: -47px;
    transform: translateY(-50%);
    width: 48px;
    height: 48px;
    background-image: url("images/icon-arrow-right-car-active-lp-enchant.svg");
    background-repeat: no-repeat;
    background-color: unset;
    background-position: center;
    display: block;
    cursor: pointer;
    border: none;
}

.enchant-s4-title {
    margin-bottom: 7vh;
}

.enchant-s4-title label {
    font-family: Montserrat;
    font-weight: 600;
    font-size: 6.3vh;
    line-height: 120%;
    letter-spacing: 0px;
    text-align: center;
    color: #242424;
}

.enchant-s4-title p {
    width: 758;
    font-family: Montserrat;
    font-weight: 400;
    font-size: 2.9vh;
    line-height: 150%;
    letter-spacing: 0px;
    text-align: center;
    color: #242424;
}

.enchant-s4-content {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    position: relative;
    gap: 5vh;
    width: 80%;
    margin: auto
}

.enchant-s4-content .card img{
    width: 5vh;
    height: 5vh;
    gap: 1px;
    border-radius: 4px;
    padding: 8px;
}

.enchant-s4-content .card label{
    font-family: Montserrat;
    font-weight: 600;
    font-size: 3.8vh;
    line-height: 130%;
    letter-spacing: 0px;
    color: #242424;
}

.enchant-s4-content .card p{
    font-family: Montserrat;
    font-weight: 400;
    font-size: 2.5vh;
    line-height: 170%;
    letter-spacing: 0px;    
    color: #242424;
}

.enchant-s4-content .card-title{
    display: flex;
    align-items: center;
    gap: 16px;
    margin-bottom: 1.9vh;
}

.enchant-s4-content .card{
    width: 48%;
    height: 44%;
    gap: 16px;
}

.enchant-s4-title{
    display: flex;
    text-align: center;
    flex-direction: column;
    align-items: center;
    gap: 16px;
}

.enchant-s5{
    background-color: #F8F9FA;
    display: flex;
    gap: 40px;
    border-radius: 16px;
    padding: 32px;
    flex-direction: column;
}

.enchant-s5-title{
    display: flex;
    text-align: center;
    flex-direction: column;
    align-items: center;
}

.enchant-s5-content {
    width: 80%;
    margin: auto;
    justify-content: center;
    position: relative;
    gap: 16px;

}

.enchant-s5-carrossel {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    position: relative;
    gap: 32px;
}

.enchant-s5-content .card .card-user {
    display: flex;
    align-items: center;
    gap: 16px;
    margin-bottom: 3.8vh;
}

.enchant-s5-content .card .card-user img {
    width: 9.4vh;
    height: 9.4vh;
    gap: 10px;
    border-radius: 50px;
}

.enchant-s5-title {
    font-family: Montserrat;
    font-weight: 600;
    font-size: 6.3vh;
    line-height: 120%;
    letter-spacing: 0px;
    text-align: center;
    color: #242424;
}

.enchant-s5-content .card-user-info label{
    font-family: Montserrat;
    font-weight: 700;
    font-size: 2.9vh;
    line-height: 150%;
    letter-spacing: 0px;
    color: #242424;
}

.enchant-s5-content .card-user-info p{
    font-family: Montserrat;
    font-weight: 600;
    font-size: 2.5vh;
    line-height: 170%;
    letter-spacing: 0px;
    color: #ED0E11;
}

.enchant-s5-content .card-user-comment {
    font-family: Montserrat;
    font-weight: 400;
    font-size: 2.5vh;
    line-height: 170%;
    letter-spacing: 0px;
    color: #242424;
}

.enchant-s5 .card {
    background-color: white;
    width: 100%;
    gap: 24px;
    border-radius: 16px;
    padding: 30px;
}

.enchant-s5 .slick-slide {
    margin: 0 10px;
}

.enchant-s6-title {
    font-family: Montserrat;
    font-weight: 600;
    font-size: 6.3vh;
    line-height: 120%;
    letter-spacing: 0px;
    text-align: center;
    color: #242424;
    margin-bottom: 7vh;
}


.enchant-s6-content {
    display: flex;
    background: white;
    padding: 15px;
    border-radius: 10px;
    flex-direction: column;
    align-items: center;
}

.enchant-s6-content .card {
    width: 80%;
    padding-top: 32px;
    padding-bottom: 32px;
    border-bottom: 1px solid #ddd;
}

.enchant-s6-content .card-label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    font-family: Montserrat;
    font-weight: 600;
    font-size: 2.9vh;    
    line-height: 130%;
    letter-spacing: 0px;
    color: #242424;

}

.enchant-s6-content .card-label img {
    width: 20px;
    transition: transform 0.3s ease;
}

.enchant-s6-content .card-input {
    display: none;
}

.enchant-s6-content .card-answer {
    max-height: 0px;
    overflow: hidden;
    transition: max-height 0.5s ease, padding 0.3s ease;
    font-family: Montserrat;
    font-weight: 400;
    font-size: 1.9vh;
    line-height: 170%;
    letter-spacing: 0px;
    color:#242424;
}

.enchant-s6-content .card-input:checked + .card-label img {
    transform: rotate(180deg);
}

.enchant-s6-content .card-input:checked ~ .card-answer {
    max-height: 100px;
    padding: 32px 0px 10px 0px;
}

.enchant-s7 {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.enchant-s7-content {
    width: 80%;
    height: 65vh;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    justify-content: space-between;
    padding-left: 80px;
    position: relative;
    background: #ED0E11;
    display: flex;
    align-items: center;
}

.enchant-s7-left {
    width: 84vh;
    height: 48.5vh;
    gap: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.enchant-s7 .enchant-s7-background {
    width: 85vh;
    height: 65vh;
    object-fit: cover; 
    position: absolute; 
    border-top-right-radius: 16px;
    top: 0; 
    right: 0;
}

.enchant-s7-texto {
    display: flex;
    flex-direction: column;
    gap: 32px;
}

.enchant-s7 .enchant-s7-texto label{
    font-family: Montserrat;
    font-weight: 600;
    font-size: 6.3vh;
    line-height: 120%;
    letter-spacing: 0px;
    color: #FFFFFF;
}

.enchant-s7 .enchant-s7-texto img{
    width: 21.6vh;
    height: 7.2vh;
}

.enchant-s7 button {
    width: 31.5vh;
    height: 8.8vh;
    gap: 12px;
    border-radius: 1.3vh;
    text-align: center;
    background: #FFFFFF;
    font-family: Montserrat;
    font-weight: 700;
    font-size: 2.5vh;
    line-height: 120%;
    letter-spacing: 0px;
    color: #ED0E11;
    border: none;

    &:hover{
        box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
        transform: scale(1.05);
        color: #ED0E119f;
        border: 1px solid #ED0E119f;
    }
}

.enchant-s2, .enchant-s3, .enchant-s4,
.enchant-s5, .enchant-s6{
    margin: auto auto 8vh auto;
}

@media (max-width: 769px) {
    html, body {
        overflow-x: hidden;
    }

    .enchant-s1 {
        padding: 24px 12px 24px 12px;
    }

    .enchant-s1 .enchant-s1-logo {
        width: 110px;
        background-image: url("images/logo-lp-enchant-mobile.svg") !important;
    }

    .enchant-s2 {
        display: flex;
        height: auto;
        width: 100%;
        flex-direction: column-reverse;
        gap: 24px;
    }

    .enchant-s2 h1 {
        font-size: 4.9vh;
    }

    .enchant-s2 p {
        font-size: 2.8vh;
    }

    .enchant-s2-left {
        padding: 10px;
        gap: 24px;
        margin: 0px 10px;
    }

    .enchant-s2-texto {
        gap: 26px;
    }

    .enchant-s2 img {
        width: auto;
        border-bottom-left-radius: 100px;
        margin-left: 20px;
    }

    .enchant-s2 button {
        width: 100%;
    }

    .enchant-s2 label {
        font-size: 32px;
        line-height: 38.4px;
        letter-spacing: 0px;
    }

    .enchant-s3-title{
        align-items: flex-start;
        padding: 10px;
        margin: 0px 10px 40px 10px;
    }

    .enchant-s3-title label {
        text-align: left;
        font-size: 28px;
        line-height: 36.4px;
        letter-spacing: 0px;
    }

    .enchant-s3-title p {
        width: 100%;
        text-align: left;
        font-size: 2.5vh;
    }

    .enchant-s3-slides {
        width: 100%;
        display: flex;
        gap: 16px;
        margin-left: 12px;
    }

    .enchant-s3-carrossel label {
        text-align: left;
    }

    .enchant-s3 .card {
        text-align: start;
    }

    .enchant-s3-carrossel p {
        text-align: left;
    }

    .enchant-s3 .slick-slide {
        margin: unset;
        padding: 0px 8px;
    }
    
    .enchant-s3-carrossel {
        justify-content: unset;
    }
    .enchant-s4-content {
        padding: 0px 10px 0px 10px;
        width:100%;
        margin: 0px 10px;
    }

    .enchant-s4-content .card {
        width: 100%;
    }

    .enchant-s4-title {
        align-items: flex-start;
        padding: 0px 10px 0px 10px;
        margin-left: 10px;
        margin-right: 10px;
    }

    .enchant-s4-title label {
        font-size: 28px;
        line-height: 36.4px;
    }

    .enchant-s4-title p {
        width: auto;
        text-align: start;
        font-size: 2.4vh;
    }

    .enchant-s4-content .card img {
        width: auto;
        height: auto;
    }

    .enchant-s5 {
        padding: 10px;
    }

    .enchant-s5 {
        padding: 40px 0px 40px 0px;
    }

    .enchant-s5-content {
        width: 100%;
    }

    .enchant-s5-title {
        text-align: left;
        font-size: 28px;
        line-height: 36.4px;
        padding-left: 10px;
        margin: 0px 10px;
    }

    .enchant-s5 .card {
        max-width: 311px;
        height: unset;
    }
    

    .enchant-s6-content .card {
        width: auto;
    }

    .enchant-s6-content .card-label {
        font-size: 20px;
        line-height: 26px;
    }

    .enchant-s6-content {
        margin: 0px 5px;
    }

    .enchant-s6-title {
        font-size: 28px;
        line-height: 36.4px;
        text-align: start;
        padding: 0px 10px 0px 10px;
        margin: 0px 10px;
    }

    .enchant-s6-content .card-answer {
        font-size: 2.4vh;
    }

    .enchant-s6-content .card-input:checked ~ .card-answer {
        max-height:fit-content;
    }

    img.enchant-s7-background {
        display: none;
    }

.enchant-s7 .enchant-s7-texto label {
    font-size: 32px;
    line-height: 38.4px;
}

.enchant-s7-left {
    width: auto;
}

.enchant-s7-content {
    width: auto;
    height: auto;
    justify-content: space-between;
    padding-top: 32px;
    padding-right: 20px;
    padding-bottom: 56px;
    padding-left: 20px;
    border-radius: unset;
}

.enchant-s7 button {
    width: auto;
}

    .enchant-s2, .enchant-s3, .enchant-s4,
.enchant-s5, .enchant-s6{
    margin: 0px auto 40px auto;
}
}

@media (max-width: 768px) {
    .slick-slide {
      width: 311px !important; /* Largura fixa */
    }
    .slick-track {
      display: flex;
    }
  }
